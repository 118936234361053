import axios from "axios";

import {showModal} from '@/plugins/modal'
import { useUserStore } from "@/stores/user";
// import { memoizedRefreshToken } from "../utilities/refreshToken";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
import { useAuthStore } from "@/stores/auth";

//  Get 

const HTTP = axios.create({  
  headers: {    
    Accept: "*/*",
    "Content-Type": "application/json",
  },
});
function requestLogout(){
  if(!window.modalOpened){
      showModal({    
        text: 'Network Error: Probably session expired. Logout?',  
      }).then((result) => {        
        /* Read more about isConfirmed, isDenied below */
        if (result) {
        const store = useAuthStore();
        store.logout()
      }/*  else if (result.isDenied) {
        
      } */
    })
  }
}
function sessionExpired(){
  if(!window.modalOpened){
  showModal({
    text: 'Session expired!',
    confirmText: 'Login',
    cancelText: 'Cancel',
  }).then(function(){    
    const store = useAuthStore();
    store.logout()
  });
}
}

function displayOk(text){
  if(!window.modalOpened){
    showModal({
      text:text,
      confirmText: 'Ok',
      cancelText: 'Cancel',
    })    
  }
}
function excludeLogin(callback=()=>{}){
  const currentUrl = window.location.href;
  if (currentUrl.includes("login")) {
    if(!window.modalOpened){
      showModal({text:"Invalid credentials"});
    }
  } else {
    callback()
  }
}
//if url false use api else use admin
export const get = async (resource, prefix = "api", defaults=true) => {
  const token = localStorage.getItem("userToken");
  const store = useUserStore();
  if (prefix == "admin") {
    resource =window.baseUrl+ "admin/" + resource;
  } else if(prefix=='api') {
    resource = window.baseUrl+ "api/" + resource;
  } 
  store.isLoading = true;
  try {
    const response = await HTTP.get(resource, {
      headers: {
        "Content-Type": "application/json", 
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    store.isLoading = false;
    let obj = typeof response.data === "object" ? response.data : {};
    if (Object.keys(obj)?.includes("data")) {
      if(defaults){
        return response.data;
      }
      return response.data.data.responseBody; //for now
    } else if (Object.keys(obj)?.includes("status") === "success") {
      if(defaults){
        return response.data;
      }
      return response.data;
    } else {
      if(defaults){
        return response.data;
      }
      return response.data;
      //throw new Error(response?.data.message ?? "Something went wrong!");
    }
  } catch (e) {
    store.isLoading = false;    
    if (e?.message === "Network Error") {                      
        excludeLogin(requestLogout())
    }else if(e.response.status === 401 || e.response.status === 302){
        excludeLogin(sessionExpired())     
      window.modalOpened = true
    }else {
      const message =
      e.response?.data?.status !== 400
        ? ["Something went wrong"]
        : [
            typeof e.response?.data === "object" ? "" : e.response?.data,
            e.response?.data?.message,
            Array.isArray(e.response?.data?.responseBody)
              ? e.response?.data?.responseBody.join(",\n")
              : e.response?.data?.responseBody,
          ];  
       displayOk(message.join('@').replaceAll('@',' '))
    }

    return false;
  }
};

export const post = async (resource, data, prefix = "api", defaults = true) => {
  const token = localStorage.getItem("userToken");
  if (prefix == "admin") {
    resource = "admin/" + resource;
  } else if(prefix=='api') {
    resource = "api/" + resource;
  }  
  
  const store = useUserStore();
  store.isLoading = true;  
  try {
    const response = await HTTP.post( window.baseUrl+resource, data, {
      headers: {
        "Content-Type": "application/json", 
        Authorization: token ? `Bearer ${token}` : "",
      },
    });    
    store.isLoading = false;
    let obj = typeof response.data === "object" ? response.data : {};
    if (Object.keys(obj)?.includes("data")) {
      if(defaults){
        return response.data;
      }
      return response.data.data.responseBody; //for now
    } else if (Object.keys(obj)?.includes("status") === "success") {      
      if(defaults){
        return response;
      }
      return response.data;
    } else {
      if(defaults){
        return response;
      }
      return response.data;
      //throw new Error(response?.data.message ?? "Something went wrong!");
    }
  } catch (e) {
    store.isLoading = false;
    if (e?.message === "Network Error") {
      excludeLogin(displayOk('Network Error!'))
    }else if(e.response.status === 401 ||e.response.status === 302){
      excludeLogin(sessionExpired())
      window.modalOpened = true
    }else {      
      const message =
      (e.response?.status !== 400)
        ? ["Something went wrong"]
        : [
            (typeof e.response?.data.message == "string") || (typeof e.response?.data.responseBody =='string')  ? 
            e.response?.data.message || e.response?.data.responseBody:
            Array.isArray(e.response?.data?.responseBody)
              ? e.response?.data?.responseBody?.join(",\n") || e.response?.data?.message?.join(",\n")
              : e.response?.data?.responseBody
          ];
       displayOk(message.join('@').replaceAll('@',' '))
    }

    return false;
  }
};

export const postFormData = async (resource, data, prefix = 'api') => {
  const token = localStorage.getItem("userToken");
  if (prefix == "admin") {
    resource = "admin/" + resource;
  } else if(prefix=='api') {
    resource = "api/" + resource;
  }  
  const store = useUserStore();
  store.isLoading = true;
  try {
    
    const response = await HTTP.post( window.baseUrl+resource, data,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          "Content-Type": "multipart/form-data", 
        },
      });
    store.isLoading = false;
    let obj = typeof response.data === "object" ? response.data : {};
    if (Object.keys(obj)?.includes("data") || Object.keys(response.data).includes('responseBody')) {           
      return response.data.responseBody; //for now
       
    } else if (Object.keys(obj)?.includes("status") === "success") {
      return response.data;
    } else {
      return response.data;
      //throw new Error(response?.data.message ?? "Something went wrong!");
    }
  } catch (e) {
    store.isLoading = false;
    
    if(e.response?.status === 401 || e.response?.status === 302){
     sessionExpired()
window.modalOpened = true
    }else 
    if (e?.message === "Network Error") {
      displayOk('Network Error!')
    } else {
      const  message= [
        typeof e.response?.data == 'object'?'':e.response?.data,
         e.response?.data?.message,
         e.response?.data?.responseBody,
       ]
       displayOk(message.join('@').replaceAll('@',' '))
    }

    return false;
  }
  // axios.defaults.baseURL = "http://localhost:3333/api";
};

export const axiosPrivate = axios;
