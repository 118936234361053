import { createApp, reactive } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
// import piniaPersist from 'pinia-plugin-persist'

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "../src/assets/styles/app.css";
import "../src/assets/styles/color.css";
import "../src/assets/styles/buttons.css";
import "../src/assets/styles/utilities.css";
import "../src/assets/styles/animation.css";
import "vue-material-design-icons/styles.css";
import PrimeVue from "primevue/config";
//import 'primevue/resources/themes/saga-blue/theme.css';       //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; // icons
import "../src/assets/theme.css";

import OtpInput from "@bachdgvn/vue-otp-input";
// import 'vue-phone-number-input/dist/vue-phone-number-input.css';
//import VuePhoneNumberInput from "vue-phone-number-input";

import ConfirmationService from "primevue/confirmationservice";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import InputSwitch from "primevue/inputswitch";

import InputMask from "primevue/inputmask";
import ToastService from "primevue/toastservice";
import VOtpInput from "vue3-otp-input";
// import OtpInput from '@bachdgvn/vue-otp-input';
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { globals } from "./stores/globals";
// import Vue plugin
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import initializeEcho from './plugins/pusher.js'; 
// import polyfills for IE if you want to support it
import "vue-svg-inline-plugin/src/polyfills";
import CKEditor from '@ckeditor/ckeditor5-vue';
const app = createApp(App);
app.use(VueSvgInlinePlugin);
//app.component("vue-phone-number-input", VuePhoneNumberInput);
const pinia = createPinia();
// pinia.use(piniaPersist)
app.use(pinia); // Create the root store
app.use(router);
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService);
app.use( CKEditor );
app.component("v-otp-input", OtpInput); 
app.component("CalendarComponent", Calendar);
app.component("InputMask", InputMask);
app.component("InputSwitch", InputSwitch);
app.component("InputText", InputText);
app.component("DropDown", Dropdown);
app.component("v-otp-input", VOtpInput);
const deepGlobals = reactive({
  ...globals,
  notification: reactive(globals.notification),
  chats: reactive({
    ...globals.chats,
    highschool: reactive(globals.chats.highschool),
    university: reactive(globals.chats.university)
  })
});
app.config.globalProperties.$globals = deepGlobals;
window.baseUrl = `https://crm.paramountstudents.com/`;
//window.baseUrl = `https://crm.paramountstudents.com/`;
//window.baseUrl = `http://localhost:8000/`;
app.mount("#app");
if(!window.Echo && localStorage.getItem('user')){
    initializeEcho(window.baseUrl,localStorage.getItem('user'));
}

/* 
window.Pusher = require("pusher-js");
window.Echo = new Echo({     
       authEndpoint: `${window.baseUrl}admin/broadcasting/auth`, 
       broadcaster: 'pusher',
       key: 'localapp',//import.meta.env.VITE_PUSHER_APP_KEY,
       wsHost: hostname,//import.meta.env.VITE_PUSHER_HOST,
       wsPort: 6001,//import.meta.env.VITE_PUSHER_PORT,
       disableStats: true,
       cluster:'mt1',            
       forceTLS: false,
         auth: {
         headers: {
           'Authorization': 'Bearer ' + localStorage.getItem('user')
         }
       }  
   });  */